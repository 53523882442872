import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import LayoutContext from "gatsby-theme-photography-portfolio/src/context/LayoutContext"//"../context/LayoutContext"
import Sidebar from "../components/SidebarCms"
import Grid from "../components/GridCms"
import { Main, SidebarButtonWrapper,FooterHolder } from "../utils/styles"//"../utils/styles"
import { CloseIcon, HamburgerIcon } from "gatsby-theme-photography-portfolio/src/components/ui/icons"//"../components/ui/icons"
import MiniHeader from "../components/MiniHeader"
import Layout from "../components/layout"
import Seo from "../components/seo"



const getInfo = (contentfulWorks) => {
  console.log("contentfulWorks!!", contentfulWorks);
  //console.log("...contentfulPage",contentfulPage)
}


const WorkPage = ({ data: { contentfulWorks, allContentfulWorks } }) => {
  console.log("contentfulWorks!!", contentfulWorks);
  //getInfo(allContentfulWorks)
  const { sidebarVisible, toggleSidebar } = useContext(LayoutContext)

  const categories = allContentfulWorks.edges

  // TUTTE LE IMMAGIN IN MEDIA TRANNE LA PRIMA
  const images = contentfulWorks.media.slice(1);


  return (
    <>
      <Seo title={contentfulWorks.title} />
      <SidebarButtonWrapper>
        {sidebarVisible ? (
          <CloseIcon onClick={toggleSidebar} />
        ) : (
            <HamburgerIcon onClick={toggleSidebar} />
          )}
      </SidebarButtonWrapper>
      <Sidebar categories={categories} />

      <Main sidebarVisible={sidebarVisible} onClick={sidebarVisible?toggleSidebar:null}>
        <MiniHeader sidebarVisible={sidebarVisible}>
        </MiniHeader>
        <Grid 
        title={contentfulWorks.title} 
        description={contentfulWorks.description} 
        descriptionShort={contentfulWorks.descriptionShort} 
        exhibitions={contentfulWorks.exhibitions}
        publications={contentfulWorks.publications}
        awards={contentfulWorks.awards}
        imagePreview={contentfulWorks.preview} 
        archived={contentfulWorks.archived}
        images={images} prefix="" />
        <FooterHolder
          style={{
            marginTop: `var(--space-5)`,
            fontSize: `var(--font-sm)`,
          }}
        >
          <div className="copy-footer">© Copyright <span>NADIA SHIRA COHEN 2023</span> </div>
          <div className="gerenza">Built by <span>Emiliano Pallini</span></div>
        </FooterHolder>
      </Main>
      
    </>

  )

}

export const data = graphql`
  query pageWorks($id: String) {
    contentfulWorks(id: { eq: $id }) {
      
      title
      id
      description {
        raw
      }
      descriptionShort {
        raw
      }
      awards {
        id
        name
        url
      }
      exhibitions {
        id
        location
        name
        url
      }
      publications {
        id
        title
        magazine
        url
        pdf {
          file {
            url
          }
        }
      }
      media {
        gatsbyImageData(layout: FULL_WIDTH)
        title
      }
      preview {
        gatsbyImageData
        title
        id
      }
    }
    allContentfulWorks(sort: {fields: order}) {
      edges {
        node {
          title
          archived
          id
        }
      }
    }
    
    
  }
`



export default WorkPage
